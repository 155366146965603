<template>
  <div>
    <div class="card p-3 shadow-sm">
      <form @submit.prevent="updatePsc">
        <div class="row justify-content-between">
          <div class="col-auto">
            <h5 class="e-text-red">
              PORT STATE CONTROL (PSC) INSPECTION
            </h5>
          </div>
          <div class="col-auto">
            <template v-if="toEdit">
              <button class="e-btn e-btn-outlined-red ml-2" type="button" @click="cancelUpdate" v-if="toEdit">Cancel</button>
              <button class="e-btn e-btn-blue ml-2" type="submit">Save</button>
            </template>
            <template v-else>
              <button class="e-btn e-btn-green ml-2 px-3" type="button"
                      @click="editMainReport"
                      v-if="isAllowed('psc.main.edit') && isOwnVessel('pscReport')">
                Edit Report
              </button>
              <div class="float-right ml-2 dropdown dropright"
                   v-if="isAllowed('psc.main.download') && isOwnVessel('pscReport')">
                <button id="actionAIRDropdown" aria-expanded="false" aria-haspopup="true" class="mr-2 e-btn e-btn-blue-gray force-white-all"
                        data-toggle="dropdown">
                  <font-awesome-icon class="mr-1" icon="download"/>
                  Download PSC Inspection
                </button>
                <div aria-labelledby="actionAIRDropdown" class="dropdown-menu">
                  <button class="dropdown-item text-secondary font-weight-bolder" type="button" @click="exportPscReport()">
                    <font-awesome-icon icon="file-excel"/>
                    Excel
                  </button>
                  <button class="dropdown-item text-secondary font-weight-bolder" type="button" @click="exportPscReport('pdf')">
                    <font-awesome-icon icon="file-pdf"/>
                    PDF
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>

        <table class="w-100">
          <tr>
            <td style="width: 12%;">Report Number</td>
            <td style="width: 50%;">{{ pscGet('code') | toUpper }}</td>
            <td style="width: 12%;">
              Status
              <font-awesome-icon icon="question-circle"
                                 data-toggle="modal"
                                 style="cursor:pointer"
                                 data-target="#requirementsBeforeClosing">
              </font-awesome-icon>
            </td>
            <td>
              <template v-if="editStatus">
                <div class="row align-content-center align-items-center">
                  <div class="col-auto">
                    <select class="form-control form-control-sm" v-model="reportStatus.report_status_log.report_status_id">
                      <template v-for="reportStatusKey in reportStatusKeys">
                        <option v-if="reportStatusKey.visible" :value="reportStatusKey.id" :disabled="reportStatusKey.disabled">
                          {{ reportStatusKey.name }}
                        </option>
                      </template>
                    </select>
                  </div>
                  <div class="col-auto">
                    <button type="button" class="btn btn-success btn-xs mr-2"
                            :disabled="currentStatus===reportStatus.report_status_log.report_status_id" @click="saveStatus">
                      <font-awesome-icon icon="save" class="force-white-all">

                      </font-awesome-icon>
                      Save
                    </button>
                    <button type="button" class="btn btn-danger btn-xs" @click="editStatus=false;">
                      <font-awesome-icon icon="ban" class="force-white-all"></font-awesome-icon>
                      Cancel
                    </button>
                  </div>
                </div>
              </template>
              <button v-else type="button" class="e-btn e-btn-xs px-5" @click="editReportStatus" :class="['bg-'+pscGet('report_status_color')]">
                {{ pscGet('report_status_name') | toUpper }}
              </button>
            </td>
          </tr>
          <tr>
            <td>Vessel <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td class="text-uppercase">
              {{ pscGet('vessel_name') }} <span class="font-weight-bolder">({{ pscGet('vessel_flag_name') | toUpper }})</span>
            </td>
            <td>Date of Inspection <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td class="text-uppercase">
              <input type="date" v-if="toEdit" v-model="mainReport.inspection_date" min="1900-01-01" required
                     class="form-control-sm form-control"
                     name="date-of-inspection" id="date-of-inspection">
              <span v-else>
                  {{ pscGet('inspection_date') | moment('DD MMMM YYYY') }}
              </span>
            </td>
          </tr>
          <tr>
            <td>Report Title <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td class="text-capitalize">
              <input type="text" v-if="toEdit" v-model="mainReport.title" class="form-control-sm form-control" name="date-of-inspection"
                     id="date-of-inspection">
              <span v-else>
                 {{ pscGet('title') | toUpper }}
              </span>
            </td>
            <td>
              Last Updated By:
            </td>
            <td>{{ pscGet('last_updated_by.first_name') }} {{ pscGet('last_updated_by.last_name') }}</td>
          </tr>
        </table>
        <hr/>
        <table class="w-100">
          <tr>
            <td style="width: 15%" class="align-middle">Country of Inspection <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td class="text-uppercase align-middle">
              <select name="country-of-inspection" v-model="mainReport.general.country_id"
                      class="form-control form-control-sm" id="country-of-inspection" v-if="toEdit">
<!--                <option :value="null">-&#45;&#45; SELECT COUNTRY -&#45;&#45;</option>-->
                <option :value="country.id" v-for="country in countryKeys">
                  {{ country.name | toUpper }}
                </option>
              </select>
              <span v-else style="white-space: pre-line">{{ pscGet('general.country.name') | toUpper }}</span>
            </td>
            <td class="align-middle" style="width: 15%">Port of Inspection <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td class="text-uppercase align-middle">
              <select name="port-of-inspection" v-model="mainReport.general.port_id" class="form-control form-control-sm" id="port-of-inspection"
                      v-if="toEdit" :disabled="!filteredPort.length">
<!--                <option :value="null">-&#45;&#45; SELECT PORT -&#45;&#45;</option>-->
                <option :value="port.id" v-for="port in filteredPort">{{ (port.code ? port.code.toUpperCase() : '') }}
                  {{ port.name ? port.name.toUpperCase() : '' }}
                </option>
              </select>
              <span v-else style="white-space: pre-line">{{ pscGet('general.port.name') | toUpper }}</span>
            </td>
            <td style="width: 10%" class="align-middle" @click="changeHeaderAlignment">
              MOU
              <font-awesome-icon icon="question-circle" style="cursor:pointer" title="test hover" data-toggle="modal"
                                 data-target="#popper-mou-states"></font-awesome-icon>
            </td>
            <td class="text-uppercase align-middle">
              <select name="general-mou" v-model="mainReport.general.mou_id" class="form-control form-control-sm" id="general-mou" v-if="toEdit">
                <option :value="null">--- SELECT MOU ---</option>
                <option :value="mou.id" v-for="mou in mouKeys">
                  {{ (mou.name ? mou.name.toUpperCase() : '') }}
                  {{ mou.description ? mou.description.toUpperCase() : '' }}
                </option>
              </select>
              <span v-else style="white-space: pre-line">
                {{ pscGet('general.mou_name_description') | toUpper }}
              </span>
            </td>
          </tr>
          <tr>
            <td class="align-middle" style="width: 15%">Management Branch Company <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td class="text-uppercase align-middle">
              <span style="white-space: pre-line">{{
                  pscGet('general.management_office.management_company.name', tmp.managementCompanyName, true)
                }}</span>
            </td>
            <td style="width: 15%" class="align-middle">Management Branch Office <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td class="text-uppercase align-middle">
              <select name="management-branch" v-model="mainReport.general.management_office_id" class="form-control form-control-sm"
                      id="management-branch" v-if="toEdit" @change="changeTmpManagementCompanyName">
<!--                <option :value="null">-&#45;&#45; SELECT MANAGEMENT OFFICE -&#45;&#45;</option>-->
                <option :value="office.id" v-for="office in mngmntOfficeKeys ">
                  {{ office.name | toUpper }}
                </option>
              </select>
              <span style="white-space: pre-line" v-else>{{ pscGet('general.management_office.name') | toUpper }}</span>
            </td>
            <td style="width: 10%" class="align-middle"></td>
            <td class="text-uppercase align-middle"></td>
          </tr>
          <tr>
            <td class="pb-0" style="width: 15%;">Person In Charge <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td style="width: 21%;" class="text-capitalize font-weight-bold pb-0">
              <select name="management-branch" v-model="mainReport.general.person_in_charge_id" class="form-control form-control-sm"
                      id="management-branch" v-if="toEdit">
<!--                <option :value="null">-&#45;&#45; SELECT PERSON IN CHARGE -&#45;&#45;</option> -->
                <option :value="person.id" v-for="person in userKeys ">
                  {{ person.name | toUpper }}
                </option>
              </select>
              <span style="white-space: pre-line" v-else>{{ pscGet('general.person_in_charge.name') }}</span>
            </td>
            <td class="pb-0" style="width: 15%;">Responsible Person <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td style="width: 21%;" class="text-capitalize font-weight-bold pb-0">
              <select name="management-branch" v-model="mainReport.general.responsible_person_id" class="form-control form-control-sm"
                      id="management-branch" v-if="toEdit">
<!--                <option :value="null">-&#45;&#45; SELECT RESPONSIBLE PERSON -&#45;&#45;</option>-->
                <option :value="responsible.id" v-for="responsible in userKeys ">
                  {{ responsible.name | toUpper }}
                </option>
              </select>
              <span style="white-space: pre-line" v-else>{{ pscGet('general.responsible_person.name') }}</span>
            </td>
            <td class="pb-0" style="width: 10%;">Report Author <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td class="text-capitalize font-weight-bold pb-0">
              <input type="text" v-model="mainReport.general.author" v-if="toEdit" class="form-control-sm form-control" name="date-of-inspection"
                     id="date-of-inspection">
              <span style="white-space: pre-line" v-else>{{ pscGet('general.author') | toUpper }}</span>
            </td>
          </tr>
        </table>
      </form>
    </div>
    <nav class="navbar navbar-expand-lg mt-2" id="ModelNav">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
              aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item" v-if="isAllowed('psc.main.general.menu')">
            <router-link class="nav-link" :to="{ name: 'PscGeneral'}">
              <span>General</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="isAllowed('psc.main.inspection.menu')">
            <router-link class="nav-link" :to="{ name: 'PscInspection'}">
              <span>Inspection</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="isAllowed('psc.main.questionnaire.menu')">
            <router-link class="nav-link" :to="{ name: 'PscQuestionnaire'}">
              <span>Questionnaire</span>
            </router-link>
          </li>
          <li class="nav-item" v-if="isAllowed('psc.main.deficiency.menu')">
            <router-link class="nav-link" :to="{ name: 'PscDeficiency'}">
              <span>Deficiency</span>
            </router-link>
          </li>
          <!--          <li class="nav-item">-->
          <!--            <router-link class="nav-link"-->
          <!--                         :to="{ name: 'PscCompanyInstruction' }">-->
          <!--              <span>Company Instruction</span>-->
          <!--            </router-link>-->
          <!--          </li>-->
          <li class="nav-item" v-if="isAllowed('psc.main.ships-particular.menu')">
            <router-link class="nav-link white"
                         :to="{ name: 'PscShipParticular', params: {vesselId: pscReport.vessel_id } }">
              <span>Ship's Particular</span>
            </router-link>
          </li>

          <li class="nav-item" v-if="isAllowed('psc.main.ships-particular.menu')">
            <router-link class="nav-link"
                         :to="{ name: 'PscInspectionHistory', params: {vesselId: pscReport.vessel_id } }">
              <span>PSC Inspection History</span>
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
    <hr style="border-top: 2px solid #e7e7e7; margin-top: -2px"/>

    <router-view v-if="cycle.created"/>

    <PopperModal v-if="cycle.created" popper-id="popper-mou-states">
      <template #popperHeader>MOU Information</template>
      <template #popperBody>
        <table style="width: 100%;" class="table-bordered">
          <thead>
          <tr>
            <th class="text-center" style="width: 20%">MOU</th>
            <th class="text-center">MEMBER STATES</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="mou in mouKeys">
            <td class="text-center" style="vertical-align: top">{{ mou.name }}</td>
            <td>{{ mou.state }}</td>
          </tr>
          </tbody>
        </table>
      </template>
    </PopperModal>

    <PopperModal v-if="cycle.created" popper-id="requirementsBeforeClosing">
      <template #popperHeader>
        Required Fields Before Close
        <small :class="pscGet('deficiency_count') ? 'text-warning' : 'text-info'">
          ({{ pscGet('deficiency_count') > 0 ? 'Has' : 'No' }} Deficiencies)
        </small>
      </template>
      <template #popperBody>
        <table style="width: 100%;">
          <!-- Psc Report Block Start -->
          <tr>
            <th colspan="3" class="h6">
              Psc Report
            </th>
          </tr>
          <tr>
            <th>Vessel:</th>
            <td style="width: 25px;">
              <font-awesome-icon :icon="getAttrIcon(pscGet('vessel_id'))" :class="getAttrClass(pscGet('vessel_id'))"/>
            </td>
            <td :class="getAttrClass(pscGet('vessel_id'), 'e-bg-white', 'e-bg-red-light-7')">
              {{ pscGet('vessel.name') }}
            </td>
          </tr>
          <tr>
            <th>Title:</th>
            <td style="width: 25px;">
              <font-awesome-icon :icon="getAttrIcon(pscGet('title'))" :class="getAttrClass(pscGet('title'))"/>
            </td>
            <td :class="getAttrClass(pscGet('title'), 'e-bg-white', 'e-bg-red-light-7')">
              {{ pscGet('title') }}
            </td>
          </tr>
          <tr>
            <th>Inspection Date:</th>
            <td style="width: 25px;">
              <font-awesome-icon :icon="getAttrIcon(pscGet('inspection_date'))" :class="getAttrClass(pscGet('inspection_date'))"/>
            </td>
            <td :class="getAttrClass(pscGet('inspection_date'), 'e-bg-white', 'e-bg-red-light-7')">
              {{ pscGet('inspection_date') | moment('D MMM YYYY') }}
            </td>
          </tr>
          <tr>
            <th>Country:</th>
            <td style="width: 25px;">
              <font-awesome-icon :icon="getAttrIcon(pscGet('general.country_id'))" :class="getAttrClass(pscGet('general.country_id'))"/>
            </td>
            <td :class="getAttrClass(pscGet('general.country_id'), 'e-bg-white', 'e-bg-red-light-7')">
              {{ pscGet('general.country.name') }}
            </td>
          </tr>
          <tr>
            <th>Port:</th>
            <td style="width: 25px;">
              <font-awesome-icon :icon="getAttrIcon(pscGet('general.port_id'))" :class="getAttrClass(pscGet('general.port_id'))"/>
            </td>
            <td :class="getAttrClass(pscGet('general.port_id'), 'e-bg-white', 'e-bg-red-light-7')">
              {{ pscGet('general.port.name') }}
            </td>
          </tr>
          <tr>
            <th>Management Branch Company:</th>
            <td style="width: 25px;">
              <font-awesome-icon :icon="getAttrIcon(pscGet('general.management_office.management_company.name'))"
                                 :class="getAttrClass(pscGet('general.management_office.management_company.name'))"/>
            </td>
            <td class="text-uppercase" :class="getAttrClass(pscGet('general.management_office.management_company.name'), 'e-bg-white', 'e-bg-red-light-7')">
              {{ pscGet('general.management_office.management_company.name') }}
            </td>
          </tr>
          <tr>
            <th>Management Branch Office:</th>
            <td style="width: 25px;">
              <font-awesome-icon :icon="getAttrIcon(pscGet('general.management_office_id'))"
                                 :class="getAttrClass(pscGet('general.management_office_id'))"/>
            </td>
            <td class="text-uppercase" :class="getAttrClass(pscGet('general.management_office_id'), 'e-bg-white', 'e-bg-red-light-7')">
              {{ pscGet('general.management_office.name') }}
            </td>
          </tr>
          <tr>
            <th>Person In Charge:</th>
            <td style="width: 25px;">
              <font-awesome-icon :icon="getAttrIcon(pscGet('general.person_in_charge_id'))"
                                 :class="getAttrClass(pscGet('general.person_in_charge_id'))"/>
            </td>
            <td :class="getAttrClass(pscGet('general.person_in_charge_id'), 'e-bg-white', 'e-bg-red-light-7')">
              {{ pscGet('general.person_in_charge.name') }}
            </td>
          </tr>
          <tr>
            <th>Responsible Person:</th>
            <td style="width: 25px;">
              <font-awesome-icon :icon="getAttrIcon(pscGet('general.responsible_person_id'))"
                                 :class="getAttrClass(pscGet('general.responsible_person_id'))"/>
            </td>
            <td :class="getAttrClass(pscGet('general.responsible_person_id'), 'e-bg-white', 'e-bg-red-light-7')">
              {{ pscGet('general.responsible_person.name') }}
            </td>
          </tr>
          <tr>
            <th>Report Author:</th>
            <td style="width: 25px;">
              <font-awesome-icon :icon="getAttrIcon(pscGet('general.author'))" :class="getAttrClass(pscGet('general.author'))"/>
            </td>
            <td :class="getAttrClass(pscGet('general.author'), 'e-bg-white', 'e-bg-red-light-7')">
              {{ pscGet('general.author') }}
            </td>
          </tr>
          <!-- Psc Report Block End -->
          <!-- General Block Start -->
          <tr>
            <th colspan="3" class="h6 pt-3">General</th>
          </tr>
          <tr>
            <th>Charterer:</th>
            <td style="width: 25px;">
              <font-awesome-icon :icon="getAttrIcon(pscGet('general.charterer'))" :class="getAttrClass(pscGet('general.charterer'))"/>
            </td>
            <td :class="getAttrClass(pscGet('general.charterer'), 'e-bg-white', 'e-bg-red-light-7')">
              {{ pscGet('general.charterer') }}
            </td>
          </tr>
          <tr>
            <th>Operator:</th>
            <td style="width: 25px;">
              <font-awesome-icon :icon="getAttrIcon(pscGet('general.operator'))" :class="getAttrClass(pscGet('general.operator'))"/>
            </td>
            <td :class="getAttrClass(pscGet('general.operator'), 'e-bg-white', 'e-bg-red-light-7')">
              {{ pscGet('general.operator') }}
            </td>
          </tr>
          <!-- General Block End -->
          <!-- Inspection Block Start -->
          <tr>
            <th colspan="3" class="h6 pt-3">Inspection</th>
          </tr>
          <tr>
            <th>Name of Inspector:</th>
            <td style="width: 25px;">
              <font-awesome-icon :icon="getAttrIcon(pscGet('inspection.inspector_name'))"
                                 :class="getAttrClass(pscGet('inspection.inspector_name'))"/>
            </td>
            <td :class="getAttrClass(pscGet('inspection.inspector_name'), 'e-bg-white', 'e-bg-red-light-7')">
              {{ pscGet('inspection.inspector_name') }}
            </td>
          </tr>
<!--          <tr>-->
<!--            <th>Deficiencies:</th>-->
<!--            <td style="width: 25px;">-->
<!--              <font-awesome-icon :icon="getAttrIcon(!pscGet('deficiency_count') && !pscGet('inspection.deficiencies'))"-->
<!--                                 :class="getAttrClass(!pscGet('deficiency_count') && !pscGet('inspection.deficiencies'))"/>-->
<!--            </td>-->
<!--            <td :class="getAttrClass(!pscGet('deficiency_count') && !pscGet('inspection.deficiencies'), 'e-bg-white', 'e-bg-red-light-7')">-->
<!--              {{ pscGet('inspection.deficiencies') ? 'Yes' : 'No' }}-->
<!--            </td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <th>Detained:</th>-->
<!--            <td style="width: 25px;">-->
<!--              <font-awesome-icon :icon="getAttrIcon(!pscGet('deficiency_count') && !pscGet('inspection.detained'))"-->
<!--                                 :class="getAttrClass(!pscGet('deficiency_count') && !pscGet('inspection.detained'))"/>-->
<!--            </td>-->
<!--            <td :class="getAttrClass(!pscGet('deficiency_count') && !pscGet('inspection.detained'), 'e-bg-white', 'e-bg-red-light-7')">-->
<!--              {{ pscGet('inspection.detained') ? 'Yes' : 'No' }}-->
<!--            </td>-->
<!--          </tr>-->
          <tr>
            <th>Inspection Outline:</th>
            <td style="width: 25px;">
              <font-awesome-icon :icon="getAttrIcon(pscGet('inspection.outline'))" :class="getAttrClass(pscGet('inspection.outline'))"/>
            </td>
            <td :class="getAttrClass(pscGet('inspection.outline'), 'e-bg-white', 'e-bg-red-light-7')">
          <span v-if="pscGet('inspection.outline')" class="e-text-blue-gray-light-5">
            . . . See Details on "Inspection" Tab . . .
          </span>
            </td>
          </tr>
          <tr>
            <th>Attached PSC Report:</th>
            <td style="width: 25px;">
              <font-awesome-icon :icon="getAttrIcon(pscAttachments.length)" :class="getAttrClass(pscAttachments.length)"/>
            </td>
            <td :class="getAttrClass(pscAttachments.length, 'e-bg-white', 'e-bg-red-light-7')">
          <span v-if="pscAttachments.length" class="e-text-blue-gray-light-5">
            . . . See Details on "Inspection" Tab . . .
          </span>
            </td>
          </tr>
          <!-- Inspection Block End -->
          <!-- Questionnaire Block Start -->
          <tr>
            <th colspan="3" class="h6 pt-3">Questionnaire</th>
          </tr>
          <template v-for="question_answer in pscReport.question_answers">
            <tr>
              <th>{{ question_answer.psc_question.description }}</th>
              <td style="width: 25px;">
                <font-awesome-icon :icon="getAttrIcon(question_answer.answer_text != 'N/A')"
                                   :class="getAttrClass(question_answer.answer_text != 'N/A')"/>
              </td>
              <td :class="getAttrClass(question_answer.answer_text != 'N/A', 'e-bg-white', 'e-bg-red-light-7')">
                {{ question_answer.answer_text }}
              </td>
            </tr>
          </template>
          <!-- Questionnaire Block End -->
          <!-- Deficiencies Block Start -->
          <template v-if="pscGet('deficiency_count') > 0">
            <template v-for="(deficiency, index) in pscReport.psc_deficiencies">
              <tr>
                <td colspan="3">&nbsp;</td>
              </tr>
              <tr :id="'deficiency_' + parseInt(index + 1)">
                <th colspan="3" class="h5 p-1 text-center e-bg-blue e-text-white">Deficiency #{{ index + 1 }}</th>
              </tr>
              <tr>
                <th colspan="3" class="h6 pt-2">Deficiency Detail</th>
              </tr>
              <tr>
                <th>PSC Code</th>
                <td style="width: 25px;">
                  <font-awesome-icon :icon="getAttrIcon(deficiency.psc_code_id)" :class="getAttrClass(deficiency.psc_code_id)"/>
                </td>
                <td :class="getAttrClass(deficiency.psc_code_id, 'e-bg-white', 'e-bg-red-light-7')">
                  {{ deficiency.code && deficiency.code.hasOwnProperty('code') ? deficiency.code.code : '' }}
                </td>
              </tr>
              <tr>
                <th>Deficiency Description</th>
                <td style="width: 25px;">
                  <font-awesome-icon :icon="getAttrIcon(deficiency.description)" :class="getAttrClass(deficiency.description)"/>
                </td>
                <td :class="getAttrClass(deficiency.description, 'e-bg-white', 'e-bg-red-light-7')">
                  {{ deficiency.description }}
                </td>
              </tr>
              <tr>
                <th>Action Code</th>
                <td style="width: 25px;">
                  <font-awesome-icon :icon="getAttrIcon(deficiency.psc_action_code_id)" :class="getAttrClass(deficiency.psc_action_code_id)"/>
                </td>
                <td :class="getAttrClass(deficiency.psc_action_code_id, 'e-bg-white', 'e-bg-red-light-7')">
                  {{ deficiency.action_code && deficiency.action_code.hasOwnProperty('code_description') ? deficiency.action_code.code_description : '' }}
                </td>
              </tr>
              <tr>
                <th>Attached Photo Before Rectified</th>
                <td style="width: 25px;">
                  <font-awesome-icon :icon="getAttrIcon(hasAttachment(deficiency))" :class="getAttrClass(hasAttachment(deficiency))"/>
                </td>
                <td :class="getAttrClass(hasAttachment(deficiency), 'e-bg-white', 'e-bg-red-light-7')">
                  {{ hasAttachment(deficiency) ? 'Yes' : 'No' }}
                </td>
              </tr>
              <tr>
                <th>Attached Photo After Rectified</th>
                <td style="width: 25px;">
                  <font-awesome-icon :icon="getAttrIcon(hasAttachment(deficiency, 2))" :class="getAttrClass(hasAttachment(deficiency, 2))"/>
                </td>
                <td :class="getAttrClass(hasAttachment(deficiency, 2), 'e-bg-white', 'e-bg-red-light-7')">
                  {{ hasAttachment(deficiency, 2) ? 'Yes' : 'No' }}
                </td>
              </tr>
              <tr>
                <th colspan="3" class="h6 pt-3">Cause</th>
              </tr>
              <tr>
                <th>Immediate Cause > a) Substandard Act</th>
                <td style="width: 25px;">
                  <font-awesome-icon :icon="getAttrIcon(deficiency.cause.immediate_cause_id)"
                                     :class="getAttrClass(deficiency.cause.immediate_cause_id)"/>
                </td>
                <td :class="getAttrClass(deficiency.cause.immediate_cause_id, 'e-bg-white', 'e-bg-red-light-7')">
                  {{ deficiency.cause.immediate_cause && deficiency.cause.immediate_cause.hasOwnProperty('description') ? deficiency.cause.immediate_cause.description : '' }}
                </td>
              </tr>
              <tr>
                <th>Root Cause > a) Root Cause</th>
                <td style="width: 25px;">
                  <font-awesome-icon :icon="getAttrIcon(deficiency.cause.root_cause_id)" :class="getAttrClass(deficiency.cause.root_cause_id)"/>
                </td>
                <td :class="getAttrClass(deficiency.cause.root_cause_id, 'e-bg-white', 'e-bg-red-light-7')">
                  {{ deficiency.cause.root_cause && deficiency.cause.root_cause.hasOwnProperty('description') ? deficiency.cause.root_cause.description : '' }}
                </td>
              </tr>
              <tr>
                <th colspan="3" class="h6 pt-3">Corrective Action > Preparation of Corrective Actions</th>
              </tr>
              <tr>
                <th>Instruct to take Corrective Action</th>
                <td style="width: 25px;">
                  <font-awesome-icon :icon="getAttrIcon(deficiency.corrective.instruction_short)"
                                     :class="getAttrClass(deficiency.corrective.instruction_short)"/>
                </td>
                <td :class="getAttrClass(deficiency.corrective.instruction_short, 'e-bg-white', 'e-bg-red-light-7')">
                  <span v-if="deficiency.corrective.instruction_short != ''" class="e-text-blue-gray-light-5">
                    . . . See Details on "Deficiency > Corrective Action" Tab . . .
                  </span>
                </td>
              </tr>
              <tr>
                <th>Date of Instruction</th>
                <td style="width: 25px;">
                  <font-awesome-icon :icon="getAttrIcon(deficiency.corrective.instruction_date)"
                                     :class="getAttrClass(deficiency.corrective.instruction_date)"/>
                </td>
                <td :class="getAttrClass(deficiency.corrective.instruction_date, 'e-bg-white', 'e-bg-red-light-7')">
                  {{ deficiency.corrective.instruction_date | moment('DD MMMM YYYY') }}
                </td>
              </tr>
              <tr>
                <th colspan="3" class="h6 pt-3">Corrective Action > Report of Corrective Actions taken</th>
              </tr>
              <tr>
                <th>Action Taken</th>
                <td style="width: 25px;">
                  <font-awesome-icon :icon="getAttrIcon(deficiency.corrective.action_taken)"
                                     :class="getAttrClass(deficiency.corrective.action_taken)"/>
                </td>
                <td :class="getAttrClass(deficiency.corrective.action_taken, 'e-bg-white', 'e-bg-red-light-7')">
                  <span v-if="deficiency.corrective.action_taken" class="e-text-blue-gray-light-5">
                    . . . See Details on "Deficiency > Corrective Action" Tab . . .
                  </span>
                </td>
              </tr>
              <tr>
                <th>Date of Report</th>
                <td style="width: 25px;">
                  <font-awesome-icon :icon="getAttrIcon(deficiency.corrective.action_date)"
                                     :class="getAttrClass(deficiency.corrective.action_date)"/>
                </td>
                <td :class="getAttrClass(deficiency.corrective.action_date, 'e-bg-white', 'e-bg-red-light-7')">
                  {{ deficiency.corrective.action_date | moment('DD MMMM YYYY') }}
                </td>
              </tr>
              <tr>
                <th>Reported By</th>
                <td style="width: 25px;">
                  <font-awesome-icon :icon="getAttrIcon(deficiency.corrective.reported_by)"
                                     :class="getAttrClass(deficiency.corrective.reported_by)"/>
                </td>
                <td :class="getAttrClass(deficiency.corrective.reported_by, 'e-bg-white', 'e-bg-red-light-7')">
                  {{ deficiency.corrective.reported_by }}
                </td>
              </tr>
              <tr :data-report-key="'report_status'">
                <th>Reported Status</th>
                <td style="width: 25px;">
                  <font-awesome-icon :icon="getAttrIcon(deficiency.report_status_log.report_status_id === 3)"
                                     :class="getAttrClass(deficiency.report_status_log.report_status_id === 3)"/>
                </td>
                <td :class="getAttrClass(deficiency.report_status_log.report_status_id === 3, 'e-bg-white', 'e-bg-red-light-7')">
                  {{ deficiency.report_status_name }}
                </td>
              </tr>
              <tr>
                <th colspan="3" class="h6 pt-3">Preventive Measures</th>
              </tr>
              <tr>
                <th>Detail</th>
                <td style="width: 25px;">
                  <font-awesome-icon :icon="getAttrIcon(deficiency.preventative.detail_short)"
                                     :class="getAttrClass(deficiency.preventative.detail_short)"/>
                </td>
                <td :class="getAttrClass(deficiency.preventative.detail_short, 'e-bg-white', 'e-bg-red-light-7')">
                  <span v-if="deficiency.preventative.detail_short" class="e-text-blue-gray-light-5">
                    . . . See Details on "Deficiency > Preventive Measures" Tab . . .
                  </span>
                </td>
              </tr>
            </template>
          </template>
          <!-- Deficiencies Block End -->
        </table>
      </template>
      <template #popperFooter>
        <small>NOTE: Details/Remarks are too long to display so we just put where it could be found.</small>
      </template>
    </PopperModal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ObjectMixin from "../../../mixins/ObjectMixin";
import DateMixin from "../../../mixins/DateMixin";
import PopperModal from "../../../components/elements/PopperModal";
import {AlertService} from "../../../services/AlertService";
import AttachmentMixin from "../../../mixins/attachmentMixin";
import {permissionMixin} from "@/mixins/permissionMixins";
import {ReportService} from "@/services/ReportService";
import attachment from "../accident-incident/Attachment.vue";
import jQuery from "jquery";

// eslint-disable-next-line no-undef
let $ = jQuery

export default {
  name: "PscMain",
  data() {
    return {
      // cycle.created (@hotfix for child component issues when using router-view)
      cycle: {
        created: false,
      },
      swalOptions: {
        'title': 'Sweet Alert Title',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      },
      toEdit: false,
      tmp: {
        managementCompanyName: null,
      },
      mainReport: {},
      reportStatus: {},
      filteredPort: [],
      editStatus: false,
      currentStatus: null,
      reportStatusId: null,
      currentReportStatus: {},
    }
  },
  mixins: [ObjectMixin, DateMixin, AttachmentMixin, permissionMixin],
  components: {
    PopperModal
  },
  methods: {
    ...mapActions([
      'getPscReport', 'addRequestKeys', 'setRequestKeys', 'generateRequestKeys', 'updatePscInfo', 'getCrewKeys', 'getReportStatus',
    ]),
    changeHeaderAlignment() {
      const modalTitleElement = $('.modal-title:contains("MOU Information")');
      const modalHeaderElement = modalTitleElement.closest('.modal-header');
      const modalCloseButton = modalTitleElement.next();
      modalHeaderElement.css({
        display: 'block',
        textAlign: 'center',
        height: '55px'
      });
      modalCloseButton.css({
        position : 'relative',
        top: '-30px'
      });

      modalCloseButton.children('span').css({
        verticalAlign : 'middle'
      });
    },
    setCurrentReportStatus() {
      let isReadyToClose = $('#requirementsBeforeClosing').find('[data-icon="times"]').length === 0;

      Object.keys(this.reportStatusKeys).forEach(key => {
        const READY_TO_CLOSE = 4;
        // 4 == READY TO CLOSE
        if (this.reportStatusKeys[key].id === READY_TO_CLOSE) {
          this.reportStatusKeys[key].disabled = !isReadyToClose;
        }

        if (this.reportStatusKeys[key].id === this.reportStatusId) {
          this.currentReportStatus = this.reportStatusKeys[key];
        }
      });
    },
    hasAttachment(deficiency, attachment_type = 1) {
      // attachment_type [1 = before rectified, 2 = after rectified]
      if (!deficiency.attachments.length) {
        return false;
      }

      let attachments = deficiency.attachments.filter(item => item.pscd_attachment_type_id == attachment_type);

      return !!attachments.length;
    },
    getAttrIcon(cond, cond_val_true = 'check', cond_val_false = 'times') {
      return cond ? cond_val_true : cond_val_false;
    },
    getAttrClass(cond, cond_val_true = 'e-text-green', cond_val_false = 'e-text-red') {
      // cond ? 'e-bg-white' : 'e-bg-red-light-7'
      return cond ? cond_val_true : cond_val_false;
    },
    async exportPscReport(type = 'xlsx') {
      const params = {
        id: this.$route.params.id,
        // report_status_id: this.reportStatusId,
      };

      swal.fire({title: 'Please wait'});
      swal.showLoading();

      let dateNow = (type == 'xlsx' || type == 'pdf') ? Date.now() : 'TEMPLATE';
      let filename = 'Psc_Inspection_Report_' + this.pscReport.vessel.name + '_' + dateNow + '.' + type;
      console.log(filename);
      let mime_type = (type === 'pdf' ? 'application/pdf' : 'application/vnd.ms-excel');
      let file = await ReportService.downloadPscReport(params, type)
      let url = window.URL.createObjectURL(new Blob([file.data], {type: mime_type}));

      const link = document.createElement('a');
      link.setAttribute('href', url);

      if (type === 'pdf') {
        link.setAttribute('target', '_blank');
        link.download = filename;
      } else {
        link.setAttribute('download', filename);
      }

      swal.close();
      document.body.appendChild(link);
      link.click();
    },
    editMainReport() {
      this.toEdit = true;
      this.mainReport = Object.assign({}, {
        id: this.pscGet('id'),
        inspection_date: this.pscGet('inspection_date'),
        title: this.pscGet('title'),
        general: {
          port_id: this.pscGet('general.port_id'),
          mou_id: this.pscGet('general.mou_id'),
          management_office_id: this.pscGet('general.management_office_id'),
          country_id: this.pscGet('general.country_id'),
          author: this.pscGet('general.author'),
          person_in_charge_id: this.pscGet('general.person_in_charge_id'),
          responsible_person_id: this.pscGet('general.responsible_person_id'),
        }
      })
    },
    changeTmpManagementCompanyName() {
      this.tmp.managementCompanyName = null;
      let managementOffice = {};
      let managementCompany = {};

      if (this.mainReport.general.management_office_id) {
        managementOffice = Object.values(this.mngmntOfficeKeys.filter(item => item.id === this.mainReport.general.management_office_id))[0];

        if (managementOffice.hasOwnProperty('management_company_id')) {
          managementCompany = Object.values(this.mngmtCompanyKeys.filter(item => item.id === managementOffice.management_company_id))[0];
        }
      }

      this.tmp.managementCompanyName = managementCompany.hasOwnProperty('name') ? managementCompany.name : null;
    },
    async updatePsc() {
      if (await AlertService.confirmUpdateAlert("Do you want to update ? ")) {
        const response = await this.updatePscInfo(this.mainReport);
        if (response) {
          AlertService.successAlert('PSC updated successfully', 'UPDATE PSC')
          this.resetFields()
        }
      }
    },

    resetFields() {
      this.toEdit = false;
      this.mainReport = {};
      this.filteredPort = [];
      this.editStatus = false;
      this.tmp.managementCompanyName = null;
    },

    async cancelUpdate() {
      if (await AlertService.cancelAlert()) {
        this.resetFields()
      }
    },

    editReportStatus() {
      const reportStatusId = this.pscGet('report_status_log.report_status_id')
      this.currentStatus = reportStatusId
      this.reportStatus = {
        id: this.pscGet('id'),
        report_status_log: {
          report_status_id: reportStatusId,
          psc_main_id: this.pscGet('id')
        }
      }
      this.editStatus = true
    },
    async saveStatus() {
      let currStatusName = this.reportStatusKeys.filter(stat => stat.id === this.currentStatus)[0]?.name;
      const changeReportStatusName = this.reportStatusKeys.filter(stat => stat.id === this.reportStatus.report_status_log.report_status_id)[0].name;

      currStatusName = !currStatusName ? '' : currStatusName;

      const verify = swal.fire({
        icon: 'question',
        title: 'CHANGE REPORT STATUS',
        html: `CHANGING <strong> ${currStatusName} </strong> STATUS TO <strong> ${changeReportStatusName} </strong> STATUS `,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showCancelButton: true,
        cancelButtonText: 'CANCEL',
        confirmButtonText: 'PROCEED',
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#dc3545'
      }).then(result => {
        return result;
      })

      if ((await verify).isConfirmed) {
        const response = await this.updatePscInfo(this.reportStatus);
        if (response) {
          AlertService.successAlert('PSC Report Status updated successfully', 'UPDATE PSC REPORT STATUS')
          this.resetFields()
        }

        this.reportStatusId = this.pscGet('report_status_log.report_status_id');
        await this.getReportStatus(this.reportStatusId);
        this.setCurrentReportStatus();
      }
    },
    async loadResources() {
      swal.fire({...this.swalOptions, ...{title: 'LOADING RESOURCES'}});
      swal.showLoading();
      await this.addRequestKeys([
        'getReportStatusKeys',
        'getMngmtOfficeKeys',
        'getCountryListKeys',
        'getMngmtCompanyKeys',
        'getPortKeys',
        'getUserKeys',
        'getMouKeys',
      ]);
      await this.generateRequestKeys({});

      // FOR DEBUGGING
      // await this.setRequestKeys(['getReportStatusKeys']);
      // await this.generateRequestKeys({});
      // await this.setRequestKeys(['getMngmtOfficeKeys']);
      // await this.generateRequestKeys({});
      // await this.setRequestKeys(['getCountryListKeys']);
      // await this.generateRequestKeys({});
      // await this.setRequestKeys(['getMngmtCompanyKeys']);
      // await this.generateRequestKeys({});
      // await this.setRequestKeys(['getPortKeys']);
      // await this.generateRequestKeys({});
      // await this.setRequestKeys(['getUserKeys']);
      // await this.generateRequestKeys({});
      swal.close();
    },
    async loadCms() {
      const inspection_date = this.pscGet('inspection_date');
      let vesselName = this.pscGet('vessel.name');
      vesselName = (typeof vesselName !== 'undefined') ? String(vesselName).toUpperCase() : null;
      let crewListParam = {
        onboard_from: inspection_date,
        onboard_to: inspection_date,
        vesselName: vesselName,
      }

      swal.fire({...this.swalOptions, ...{title: 'ESTABLISHING CONNECTION TO CMS'}});
      swal.showLoading();
      const response = await this.getCrewKeys(crewListParam);
      swal.close();

      if (response.hasOwnProperty('errors')) {
        let errors = response.errors
        let error_message = '';
        error_message += '<ol style="text-align: left; font-size: 1em">';
        Object.keys(errors).forEach(key => {
          let err_msg = errors[key].join(',');
          error_message += '<li class="e-text-red"><small>* ' + err_msg + '</small></li>';
        });
        error_message += '</ol>';
        await AlertService.errorAlert(error_message, 'GET CREW ONBOARD LISTS')
      }
    },
  },
  async created() {
    let params = {id: this.$route.params.id};
    let response = await this.getPscReport(params);

    if (response.status !== 200) {
      if (response.status === 404) {
        return await this.$router.push({name: 'PscIndex'});
      }

      return false;
    }

    await this.loadResources();
    await this.loadCms();
    await this.getAttachments(1, this.pscReport, 'inspection.id');

    this.reportStatusId = this.pscGet('report_status_log.report_status_id');
    await this.getReportStatus(this.reportStatusId);

    this.cycle.created = true;
  },
  async updated() {
    await this.$nextTick(() => this.setCurrentReportStatus());
  },
  computed: {
    ...mapGetters([
      'pscReport',
      'reportStatusKeys',
      'mngmntOfficeKeys',
      'mngmtCompanyKeys',
      'countryKeys',
      'portKeys',
      'requestKeys',
      'userKeys',
      'mouKeys',
      'pscAttachments',
    ]),
  },

  watch: {
    'mainReport.general.country_id'(id) {
      this.filteredPort = this.portKeys.filter(port => port.country_id === id)
    }
  }

}
</script>

<style scoped lang="scss">
.nav > li {
  margin: .5rem 0;
  padding: .5rem;
  border-radius: 5px;

  &:hover, &:hover * {
    background-color: white;
    color: #d3313c;
  }
}

.e-btn-grp > li {
  list-style: none;
  font-size: 13px;
  padding: 4px;
  border-radius: 3px;
  color: #d3313c;
  cursor: pointer;

  &:hover {
    background-color: #fbeaec;
  }

  * {
    color: #d3313c;
  }
}

#ModelNav {
  padding: 0;

  li > a {
    padding: .5rem 1rem;
    color: #435464;
  }

  li:hover a {
    border-bottom: 2px solid #d3313c;
    color: #d3313c;
  }
}

.router-link-active {
  border-bottom: 2px solid #d3313c;
  color: #d3313c;

  * {
    color: #d3313c;
  }
}

table td {
  padding: 2px !important;
}
</style>

